//ringLoader
.spinner {
  animation: rotate 1s linear infinite;
  -webkit-animation: rotate 1s linear infinite;
  -moz-animation: rotate 1s linear infinite;
  position: absolute;
  top: 40%;
  left: 50%;
  // transform: translate(-50%,-50%);
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.path {
  stroke-dasharray: 170;
  stroke-dashoffset: 20;
}