.carousel-caption-top{
    position: absolute;
    right: 0%;
    bottom: 20px;
    left: 0%;
    z-index: 10;
    background: rgba(6,6,6,0.2);
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
    top: 0;
    bottom: auto;
    transform: none;
}
.carousel-caption{
    right: 0 !important;
    bottom: 20px;
    left: 0 !important;
    width: 100%;
    background: rgba(6,6,6,0.2);
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
    
}
.carousel-caption-bottom{
    position: absolute;
    right: 0 !important;
    bottom: 20px;
    left: 0 !important;
    width: 100%;
    background: rgba(6,6,6,0.2);
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
    
}

.carousel-view{
    //used for Drag and drop image for custom CSS 
    .image-uploading, img{
        height: 500px;
        width: 100%;
    }
    @media screen and (max-height: 800px){
        .image-uploading, img{
            height: 400px;
            width: 100%;
        }
    }
}

.carousel-preview{
    .image-uploading, img{
        height: 450px;
        width: 100%;
    }
}

.carousel-control-prev, .carousel-control-next{
    z-index: 20 !important;
    top: 30% !important;
    bottom: 55% !important;
    width: 8% !important;
}

.carousel-item .ql-video{
    width:100% !important;
    height: 650px !important;
}

.carousel-indicators{
    bottom: -15px !important;
}

.carousel-control-prev-top:hover, .carousel-control-prev-top:focus, .carousel-control-next-top:hover,  .carousel-control-next-top:focus,
.carousel-control-prev-bottom:hover, .carousel-control-prev-bottom:focus, .carousel-control-next-bottom:hover, .carousel-control-next-bottom:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev-top{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: opacity 0.15s ease;
    z-index: 20 !important;
    top: 20% !important;
    bottom: 67% !important;
    width: 10% !important;
    left:0;
    opacity: 0.5;
}

.carousel-control-next-top{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: opacity 0.15s ease;
    z-index: 20 !important;
    top: 20% !important;
    bottom: 67% !important;
    width: 10% !important;
    right:0;
    opacity: 0.5;
}

.carousel-control-next-bottom {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: opacity 0.15s ease;
    z-index: 20 !important;
    top: 65% !important;
    bottom: 20% !important;
    width: 10% !important;
    right:0;
    opacity: 0.5;
}

.carousel-control-prev-bottom {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: opacity 0.15s ease;
    z-index: 20 !important;
    top: 65% !important;
    bottom: 20% !important;
    width: 10% !important;
    left:0; 
    opacity: 0.5;
}