@keyframes roll-in {
  0% {
    top: 10px;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation: pulse 1s infinite;
}

.fade-in {
  animation: fade .75s ease;
}

.quiz {
  min-height: 40vh;

  .progress {
    position: relative;
    transition: width .4s ease;
    margin-bottom: 1em;
    background: rgb(181, 181, 181);
    border-radius: 0;
    width: 100%;
    height: 2em;

    .counter {
      position: absolute;
      right: 5px;
      top: 0;
      height: 100%;
      margin: auto .5em;
      letter-spacing: .025em;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  form {
    width: 90%;
    margin: 1.5em auto;
  }

  .img-fluid {
    margin: 2em auto;
    max-width: 360px;
    display: block;
  }

  .question {
    line-height: 1.35;
    margin-bottom: .75em;
  }
  .question img, .option img{
    max-width: 100%;
    height: auto;
  }
  .optionAlignment{
    line-height: normal;
    word-break: break-word;
    margin-top: 1px;
  }
  .hover{
    cursor: pointer;
  }

  .option {
    margin-bottom: .25em;
    transition: all .25s ease;
    font-size: .9em;
    display:flex;
    padding:5px 12px;
    justify-content: space-between;
    align-items: center;

  }

  button {
    cursor: pointer;

    i {
      margin-left: .15em;
    }

    &:disabled {
      opacity: .5;
    }
  }

  //custom radio controls
  input[type="radio"] {
    position: absolute;
    left: -9999px;

    &+label {
      position: relative;
      font-weight: normal;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      margin-top: 15px;

      &::before {
        text-align: center;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #dddddd;
        border-radius: 100%;
        background: #ffffff;
      }

      &::after {
        content: '';
        width: 12px;
        height: 12px;
        background-color: #222222;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        transition: all 0.2s ease;
      }
    }
  }

  .dim,
  .correct {
    input[type="radio"]+label::before {
      border: 0;
      font-size: 1.2em;
      animation: .25s roll-in ease;
    }

    input[type="radio"]+label::after {
      display: none;
    }
  }

  .correct input[type="radio"]+label:before {
    content: '\f00C';
    font-family: "Font Awesome 5 Free" !important;
    font-weight: 900;
    color: green;
  }

  .dim input[type="radio"]:checked+label:before {
    content: '\f00d';
    font-family: "Font Awesome 5 Free" !important;
    font-weight: 900;
    color: red;
  }

  input[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0);
  }

  input[type="radio"]:checked+label:after {
    opacity: 1;
    transform: scale(1);
  }

  //end custom radio controls

  //custom checkbox controls
  input[type="checkbox"] {
    position: absolute;
    left: -9999px;

    &+label {
      position: relative;
      font-weight: normal;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666666;
      margin-top: 15px;

      &::before {
        text-align: center;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #dddddd;
        border-radius: 0px;
        background: #ffffff;
      }

      &::after {
        content: '';
        width: 12px;
        height: 12px;
        background-color:#222222;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 0px;
        transition: all 0.2s ease;
      }
    }
  }

  .dim,
  .correct {
    input[type="checkbox"]+label::before {
      border: 0;
      font-size: 1.2em;
      animation: .25s roll-in ease;
    }

    input[type="checkbox"]+label::after {
      display: none;
    }
  }

  .correct input[type="checkbox"]+label:before {
    content: '\f00C';
    font-family: "Font Awesome 5 Free" !important;
    font-weight: 900;
    color: green;
  }

  .dim input[type="checkbox"]:checked+label:before {
    content: '\f00d';
    font-family: "Font Awesome 5 Free" !important;
    font-weight: 900;
    color: red;
  }

  input[type="checkbox"]:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0);
  }

  input[type="checkbox"]:checked+label:after {
    opacity: 1;
    transform: scale(1);
  }

  //end of checkbox control
  .dim {
    opacity: 0.5;
  }

  .bottom {
    width: 90%;
    margin: 10px auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    div {
      flex: 1 1 70%;
      font-size: .9em;
    }

    .next {
      flex: 0 1 10%;
    }

    @media (max-width: 600px) {
      div,
      .next {
        flex-basis: 100%;
      }

      .next {
        margin-left: 0;
      }
    }
  }

  .get-results {
    display: block;
    margin: 2em auto;
  }

  .results {
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 40vh;

    button {
      margin-top: 1em;
    }
  }
}
