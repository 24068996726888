.background{
    display: inline-block;
    overflow: hidden !important;
    position: relative;
    padding : 0px !important;
}

.background img {
    cursor: initial !important;
    border: none !important;
    z-index: -1;
    max-width: 100%;
    height: auto;
}

#hotspotContainer img {
    cursor: initial !important;
    border: none !important;
    z-index: -1;
    max-width: 760px;
    height: auto;
}

.background div{
    position: absolute;
    cursor: pointer;
}

#hotspot .carousel-control-prev-icon {
    height: 30px !important;
    width: 30px !important;
    background-size: 100%, 100%;
    background-image:  url("../images/Previous.png") !important;
  }
  
  
#hotspot .carousel-control-next-icon {
    height: 30px !important;
    width: 30px !important;
    background-size: 100%, 100%;
    background-image:  url("../images/Next.png") !important;
  }
