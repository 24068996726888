/********************classes for hover Event************************/

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 253px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.flip-card-inner img{
  height: 167px;
  width: 100%;
  object-fit: contain;
}

/* Do an horizontal flip while hover the card*/
.flip-card:hover .flip-card-inner{
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  backface-visibility: hidden;
  word-break: break-word;
  padding: 25px 25px 0px 25px !important;
}

.flip-card-back{
  transform: rotateY(180deg);
}

/*********************classes for click event************************/

.flip {
  cursor: pointer;
  background-color: transparent;
  perspective: 1000px;
  width: 100%;
  height: 253px;
}

.inner {
  position: relative;
  z-index: 1 !important;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip .inner.flipped {
  transform: rotatey(180deg);
}


.flip .inner .front, .flip .inner .back{
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  word-break: break-word;
  padding: 25px 25px 0px 25px !important;
  z-index: -1 !important;
  overflow-y: auto !important;
}

.flip .inner img{
  height: 167px;
  width: 100%;
  object-fit: contain;
}

.flip .inner .back {
  transform: rotatey(180deg);
}
