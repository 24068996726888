.layout_container {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.layout_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

.layout_container:hover input ~ .checkmark {
  background-color: #ccc;
}

.layout_container input:checked ~ .checkmark {
  background-color: black;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.layout_container input:checked ~ .checkmark:after {
  display: block;
}

.layout_container .checkmark:after {
 	top: 6px;
	left: 6px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}
