.interactive-prgs{
    background-color: #ffffff;
    .prgs-blue{
        background-color: #0B099C;
        width: 80%;
        height: 10px;
    }
    .prgs-red{
        background-color: #D0302B;
        width: 80%;
        height: 10px;
    }
    .prgs-orange{
        background-color: #F7BA44;
        width: 80%;
        height: 10px;
    }
    .prgs-green{
        background-color: #72C069;
        width: 80%;
        height: 10px;
    }
}
.loreeic {
    position: absolute;
    right: 1%;
    top: 13%;
    visibility: hidden;   
}

.iCard:hover {
    .loreeic {
        position: absolute;
        right: 1%;
        top: 13%;
        visibility: visible;
        font-weight: bold;
        opacity: 1;
        transition: opacity 2s ease-in;
    }
}
