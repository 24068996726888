.nav-tabs .nav-item {
    outline: none;   
}

.interactivetab.nav {
    overflow: hidden;
    flex-wrap: nowrap;
    scrollbar-width: none;
    border-bottom: none;
}
.tabcontent img{
    max-width: 100%;
    height: auto;
}
.tabcontent,
.tab-control {
    padding: 8px 16px;
    transition: 0.8s;
}
.arrow{
    color: black;
    font-size: 30px;
}
.li-md-tab .nav-item.nav-link{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 0 20%;
}
.list-tab > .nav-link{
    padding:.8rem 1rem !important;
}
.li-tab {
    .nav-item.nav-link{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 0 20%;
    }
    @media (min-width: 100px) and (max-width: 320px){
        .nav-item.nav-link{
            flex: 1 0 100%;    
        }
    }
    @media (min-width: 320px) and (max-width: 767px){
        .nav-item.nav-link{
            flex: 1 0 49.95%;    
        }
    }
    @media (min-width: 768px) and (max-width: 991px){
        .nav-item.nav-link{
            flex: 1 0 33.29%;    
        }
    }
    @media (min-width: 992px) and (max-width: 1200px){
        .nav-item.nav-link{
            flex: 1 0 24.99%;    
        }
    }
}

.Round{
    border-radius: 0.25rem !important;
}

.Round a{
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

 .Sharp a{
     border-top-left-radius: 0rem !important;
     border-top-right-radius: 0rem !important;
 }

.list-tab {  
    a{
        color: inherit !important;
        background-color: none !important;
    }
    a.active{
        color: black !important;
    }
    .nav {
        background: none ;
    }
}
