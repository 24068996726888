.header {
  background-color: #D5D7D9 !important;
}

.sub_header {

  border-bottom-width: 2px !important;
}

.subheader_bottom {

  border-bottom: 3px solid
  rgba(0, 0, 0, 0.125);
}

.custom {
  width: 100px;
  height: 100px;
}

.dragDropIcon {
  display: inline-block;

  &:hover {
    cursor: grab;
  }
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  height: auto;
}

.btn-pad {
  position: absolute;
  left: 69%;
}

// icons for alignment
.iconleft::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f036";
}

.iconcenter::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f037";
}

.iconright::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f038";
}

.fontcolor {
  margin-left: -6px;
  background-color: white;
}

// styles for customization in MCQ
.customradio {
  background-color: #eee;
  border-radius: 50%;
  cursor: pointer;
}

.customradio:after {
  content: "";
  display: none;
}

.customLabel {
  font-size: 20px;
  padding: 6px;
}

.customcheckbox {
  background-color: #eee;
  border-radius: 0;
  cursor: pointer;
}

.customcheckbox:after {
  content: "";
  display: none;
}

.switch-dark .custom-control-input:checked~.custom-control-label:before {
  color: #fff;
  border-color: #343a40;
  background-color: #343a40;
}

.ql-editor .ql-video{
  width:100% !important;
  height: 450px !important;
}

.react-paginate {
  text-align: center;
  margin:20px;
}
.react-paginate ul {
  display: inline-block;
  margin-left: 20px;
  padding-left: 0;
}

.react-paginate li {
  display: inline-block;
  border: 1px solid rgb(224, 224, 224);
  color: #000;
  cursor: pointer;
  margin-right: 3px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.react-paginate li a {
    padding: 5px 10px;
    display: inline-block;
    color: #000;
    outline: none;
}

.react-paginate li.active {
  background: rgb(224, 224, 224);
  outline: none;
}

.pagination-table-style{
  word-break: break-word;
}

// icons for alignment - carousel
.iconTop::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "Top";
}

.iconDefault::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "Default";
}

.iconBottom::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "Bottom";
}
