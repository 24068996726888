/*!
 * Font-Family 
 */
@import url(https://fonts.googleapis.com/css?family=Comic+New+Courier|Arial+Black|Open+Sans|Open+Sans+Condensed|Times+New+Roman|Trebuchet+MS|Nanum+Brush+Script|&display=swap);
 
/* Brush Script MT */
@font-face {
    font-family: "Brush Script MT";
    src: url("//db.onlinewebfonts.com/t/7655d129d0addb2f08a5c1dc994aaa4b.eot");
    src: url("//db.onlinewebfonts.com/t/7655d129d0addb2f08a5c1dc994aaa4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/7655d129d0addb2f08a5c1dc994aaa4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/7655d129d0addb2f08a5c1dc994aaa4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/7655d129d0addb2f08a5c1dc994aaa4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/7655d129d0addb2f08a5c1dc994aaa4b.svg#Brush Script MT") format("svg");
}

/* Arial Black */
@font-face {
    font-family: "Arial Black";
    src: url("//db.onlinewebfonts.com/t/8984ea7fcbebdcfe77fbbc1b86e7cfe6.eot");
    src: url("//db.onlinewebfonts.com/t/8984ea7fcbebdcfe77fbbc1b86e7cfe6.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8984ea7fcbebdcfe77fbbc1b86e7cfe6.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8984ea7fcbebdcfe77fbbc1b86e7cfe6.woff") format("woff"), url("//db.onlinewebfonts.com/t/8984ea7fcbebdcfe77fbbc1b86e7cfe6.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8984ea7fcbebdcfe77fbbc1b86e7cfe6.svg#Arial Black") format("svg");
}

/* open-sans-regular */
@font-face {
    font-family: "Open Sans";
    src: url("//db.onlinewebfonts.com/t/629a55a7e793da068dc580d184cc0e31.eot");
    src: url("//db.onlinewebfonts.com/t/629a55a7e793da068dc580d184cc0e31.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/629a55a7e793da068dc580d184cc0e31.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/629a55a7e793da068dc580d184cc0e31.woff") format("woff"), url("//db.onlinewebfonts.com/t/629a55a7e793da068dc580d184cc0e31.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/629a55a7e793da068dc580d184cc0e31.svg#Open Sans") format("svg");
}