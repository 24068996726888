// Global custom styles
.btn-pill {
    border-radius: 16px !important;
}

.btn-white {
    color: #212529 !important;
    background-color: #ffffff !important;
    box-shadow: none !important;
}

.icon-plus:after {
    font-family:"Font Awesome 5 Free";  
    content: "\f067";
    float: right; 
    font-weight: 900;
}

.icon-minus:after {
    font-family:"Font Awesome 5 Free";  
    content: "\f068";
    float: right; 
    font-weight: 900;
}

.icon-down-arrow:after {
    font-family:"Font Awesome 5 Free";  
    content:"\f107";
    float: right; 
    font-weight: 900;
}

.icon-right-arrow:after {
    font-family:"Font Awesome 5 Free";  
    content:"\f105";
    float: right; 
    font-weight: 900;
}

.btn-white:hover {
    background-color: #212529 !important;
    color: #ffffff !important;
}

// form customisation
.small-card {
    user-select: none;
    width: 80%;
    min-width: 5rem !important;
    padding: 1rem .5rem;
    box-sizing: border-box;
    min-height: 90px;
    font-size: 11px;
    font-weight: lighter;
    text-align: center;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 3px !important;
    margin: 10px 2.5% 5px;
    box-shadow: 0 1px 0 0 rgba(0,0,0,0.15);
    max-width: 6rem;

    .border {
        display: inline-block;
        width: 4rem;
        height: 3rem;
        background-color: #f5f5f5;
        margin: 0 auto;
        position: relative;
    }
}

.card-input-element {
    display: none;
}

.card-input:hover {
    cursor: pointer;
}

.card-input-element:checked + .card-input {
    background-color: #a8abad !important;
 }

// top header active button
.navbtn .active {
    background-color: #212529 !important;
    color: #ffffff !important;
    box-shadow: none !important;
}

// Removing scroll-bar in accordion
.ql-editor{
    overflow-y: hidden;
    height: auto;
}

// Hiding the dummy state of mcq component
.hidden{
    display: none !important
}

// For color picker width
.sketch-picker {
    width: auto !important;
}

.accordion {
    .card-header {
        cursor: pointer;
    }
}

.mw-100 {
    min-width: 100px !important;
}

.close-btn {
    line-height: 1 !important;
}

.margin{
    margin: 80px auto 5px auto;
}

.accordion img{
    max-width: 100%;
    height: auto;
}
